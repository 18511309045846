import { Box, Button, Flex, MediaBox, Typography } from "@jewlr/storybook/core"
import styled from "styled-components"

import DividerText from "components/common/divider-text"

export const ItemTitle = styled(Typography).attrs((props) => ({
  color: "black",
  fontSize: 16,
  fontWeight: 500,
  letterSpacing: "-0.02em",
  lineHeight: "18px",
  ...props,
}))``

export const ItemAction = styled(Button).attrs((props) => ({
  color: "primary",
  display: "block",
  fontSize: "14px",
  fontWeight: "300",
  lineHeight: "22px",
  variant: "text",
  ...props,
}))``

export const Card = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 12px;
  box-shadow: 0 4px 14px 0 #ebebeb;
  margin: ${(props) => props.margin || "0"};
  padding: 15px 10px 10px;
  position: relative;
`
export const CardTitle = styled.a`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey5};
  color: ${(props) => props.theme.colors.primary};
  display: block;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.3;
  margin: 0;
  padding-bottom: 15px;
  padding-left: 15px;
`
export const CardItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0 0 0 25px;
`
export const CardRemove = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.grey[65]};
  border-radius: 50%;
  color: ${(props) => props.theme.colors.grey[65]};
  display: block;
  font-size: 16px;
  height: 28px;
  justify-content: center;
  left: -12px;
  line-height: 1;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  padding: 5px;
  position: absolute;
  text-align: center;
  top: -12px;
  width: 28px;
  z-index: 1;
  :active {
    background-color: ${(props) => props.theme.colors.grey10};
  }
`
export const CardItemAction = styled.a`
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
  margin-left: -5px;
  padding: 5px;
  white-space: nowrap;
  :active {
    background-color: rgba(0, 0, 0, 0.06);
  }
`
export const AddonSliderWrapper = styled.div`
  position: relative;
`
export const AddonSlider = styled(Box).attrs((props) => ({
  as: "ul",
  gap: 1,
  ...props,
}))`
  display: flex;
  list-style: none;
  margin: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0;
  position: relative;
  -webkit-overflow-scrolling: touch;
`
export const AddonItem = styled(Box).attrs((props) => ({
  as: "li",
  ...props,
}))`
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  position: relative;
`
export const AddonButton = styled(Button).attrs({
  variant: "text",
})`
  border: ${(props) =>
    props.selected ? `2px solid ${props.theme.colors.selected}` : "none"};
  border-radius: 5px;
  position: relative;
  &:focus-visible {
    outline-offset: -2px;
  }
`
export const AddonDetails = styled(Button).attrs((props) => ({
  color: "primary",
  fontSize: "12px",
  fontWeight: "700",
  lineHeight: "14px",
  mx: "auto",
  p: 0,
  ...props,
}))``
export const TitleDivider = styled(DividerText).attrs((props) => ({
  bgColor: props.theme.colors.grey[4],
}))`
  margin-bottom: 8px;
  margin-top: 36px;
`

export const TitleDividerText = styled(Typography).attrs((props) => ({
  as: "span",
  fontSize: "20px",
  fontWeight: "700",
  ...props,
}))`
  letter-spacing: 0;
`

export const Wrapper = styled(Box).attrs((props) => ({
  ...props.theme.cart.paymentModal.wrapper,
}))`
  height: 100%;
  padding-bottom: 85px;
  ${(props) => props.theme.mediaQueries.desktop`
    padding-bottom: 40px;
  `}
  ${(props) => props.theme.mediaQueries.mobileOnly`
    margin-bottom: -55px;
  `}
`
export const Method = styled(Box).attrs((props) => ({
  ...props.theme.cart.paymentModal.method,
}))`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  ${(props) =>
    props.disable &&
    `
    border-top: 2px solid;
    border-width: 2px;
    border-color: ${props.theme.colors.selected};
  `}
`
export const MethodName = styled(Box).attrs((props) => ({
  ...props.theme.cart.paymentModal.methodName,
}))`
  align-items: center;
  color: ${(props) => props.theme.colors.black};
  display: inline-flex;
  flex: 1;
`
export const MethodDetails = styled(Typography).attrs((props) => ({
  ...props.theme.cart.paymentModal.methodDetails,
}))`
  margin: 0;
`
export const MethodIcon = styled.img`
  font-size: 22px;
  ${(props) => props.theme.cart.paymentModal.methodIcon}
`
export const MethodArrow = styled(Box).attrs((props) => ({
  ...props.theme.cart.paymentModal.methodArrow,
}))`
  font-size: 24px;
`
export const LoadingIcon = styled.div`
  color: ${(props) => props.theme.colors.grey[20]};
  display: inline-block;
  font-size: 22px;
  margin-right: 20px;
`
export const QtyIcon = styled(Button).attrs((props) => ({
  color: "grey.65",
  fontSize: "16px",
  variant: "text",
  ...props,
}))`
  margin: -4px 0;
  padding: 4px 8px;
  &:active {
    opacity: 0.2;
  }
  &:focus {
    outline: 2px solid ${(props) => props.theme.colors.white};
  }
  ${(props) =>
    props.disabled &&
    `
    opacity: 0.2;
  `}
`
export const QtyValue = styled.span`
  font-size: 12px;
  padding: 0 5px;
`

export const MainContainer = styled(Flex).attrs({
  flex: { desktop: 1 },
  flexDirection: "column",
  gap: { tablet: "16px" },
})``

export const SidePanelWrapper = styled.div`
  width: 100%;
  ${(props) => props.theme.mediaQueries.desktop`
    height: 100%;
    position: sticky;
    top: 24px;
    width: 33%;
  `}
`

export const Panel = styled(Box).attrs((props) => ({
  bg: "white",
  borderColor: "grey.20",
  borderStyle: "solid",
  borderWidth: { _: 0, tablet: "0.5px" },
  mb: { tablet: 2 },
  mx: { _: -2, tablet: 0 },
  pb: 3,
  pt: 3,
  px: { _: 2, tablet: 3 },
  ...props,
}))``

export const ShadowBox = styled(MediaBox).attrs((props) => ({
  bg: props.theme.colors.white,
  border: `0.5px solid ${props.theme.colors.grey[20]}`,
  boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
  pb: 3,
  pt: 3,
  px: "12px",
  ...props,
}))``

export const DetailBtn = styled(Button).attrs((props) => ({
  color: "link",
  display: "block",
  fontSize: 14,
  fontWeight: 300,
  lineHeight: "22px",
  variant: "text",
  ...props,
}))``

export const DetailText = styled(Typography).attrs((props) => ({
  fontSize: 12,
  fontWeight: 500,
  lineHeight: "14px",
  ...props,
}))``

export const RetailPrice = styled(Typography).attrs((props) => ({
  color: props.theme.colors.grey[62],
  fontSize: 12,
  fontWeight: 500,
  letterSpacing: "1px",
  ...props,
}))``

export const LinkButton = styled(Button).attrs((props) => ({
  color: "primary",
  fontSize: 14,
  fontWeight: 500,
  letterSpacing: "-0.02em",
  lineHeight: "20px",
  underline: true,
  variant: "text",
  ...props,
}))`
  &:focus,
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`
