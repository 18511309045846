import { Box, Button, Flex, Typography, Image } from "@jewlr/storybook/core"
import { FocusScope } from "@react-aria/focus"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import { setWarranty } from "areas/cart/store/actions"
import { to_currency } from "helpers/number"
import { useNavigation } from "helpers/use-navigation"

const Plan = styled(Button).attrs({
  variant: "text",
})`
  align-items: center;
  border: 1px solid
    ${(props) =>
      props.selected
        ? props.theme.extendWarranty.cartAddon.selected
        : props.theme.colors.grey[10]};
  border-radius: ${(props) => (props.label ? "0 0 5px 5px" : "5px")};
  flex-direction: column;
  height: 104px;
  justify-content: space-evenly;
  opacity: 0.8;
  padding: 2px;
  text-align: center;
  transition: all 0.2s;
  width: 88px;
  ${(props) => props.theme.mediaQueries.tablet`
    border-width: 2px;
    width: 96px;
  `}
  &:focus {
    border: 2px solid ${(props) => props.theme.colors.primary};
    outline: none;
  }
  ${(props) =>
    props.selected &&
    `
    position: relative;
    border: 2px solid ${props.theme.extendWarranty.cartAddon.selected};
    background-color: white;
    opacity: 1;
    &:after {
      display: block;
      position: absolute;
      content: url(${sl.config.jewlr_asset_url}images/icons/jewlr4-checkmark.svg);
      transform: scale(0.8) translateX(-50%);
      bottom: -12px;
      left: 50%;
      width: 22px;
      height: 22px;
    }
  `}
`
const PlanLabel = styled(Box).attrs((props) => ({
  borderRadius: "5px 5px 0 0",
  fontSize: "12px",
  pb: "1px",
  pt: "3px",
  ...props,
}))`
  background-color: ${(props) =>
    props.selected ? props.theme.extendWarranty.cartAddon.selected : "#4756A2"};
  color: white;
  text-align: center;
`
const PlansLink = styled(Typography).attrs(() => ({
  as: "a",
  color: "link",
  fontSize: "12px",
  fontWeight: 300,
  letterSpacing: "-0.02em",
  lineHeight: "22px",
  target: "_blank",
}))`
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`

const PlanInfo = styled(Flex).attrs((props) => ({
  flexDirection: { _: "row", tablet: "column" },
  flexWrap: "nowrap",
  gap: { _: 4, tablet: "unset" },
  justifyContent: { _: "center", tablet: "flex-start" },
  ...props.theme.extendWarranty.planInfo,
}))``

const PlanItemWrapper = styled(Flex).attrs({
  alignItems: "center",
  flexDirection: { _: "column", tablet: "row" },
  gap: { _: "6px", tablet: 1 },
  textAlign: "center",
})``

const PlansText = styled(Typography).attrs({
  fontSize: { _: "10px", tablet: "14px" },
  fontWeight: 500,
  letterSpacing: "-0.02em",
  lineHeight: { _: "12px", tablet: "22px" },
})``

const planItems = [
  {
    alt: "Accident Protection",
    img: "fragile.svg",
    order: 1,
    title: "Accident Protection",
    width: "37px",
  },
  {
    alt: "Easy Online Claims",
    img: "shield.svg",
    order: { _: 3, tablet: 2 },
    title: "Easy Online Claims",
    width: "45px",
  },
  {
    alt: "Fast Replacements and Repairs",
    img: "user-check.svg",
    order: { _: 2, tablet: 3 },
    title: "Fast Replacements + Repairs",
    width: "68px",
  },
]

const WarrantyPlanItem = ({ handleClick, plan, selected, tabIndex }) => {
  const keyboardProps = useNavigation({ direction: "horizontal" })

  return (
    <Box key={`warranty-plan-${plan.value}`}>
      {plan.label && <PlanLabel selected={selected}>{plan.label}</PlanLabel>}
      <Plan
        aria-checked={selected}
        aria-label={`${plan.duration} protection plan - ${to_currency(
          plan.price,
          {
            precision: 2,
          }
        )}`}
        data-cy={`protection-plan-${plan.duration}`}
        label={plan.label}
        onClick={handleClick}
        role="checkbox"
        selected={selected}
        tabIndex={tabIndex}
        {...keyboardProps}
      >
        <Image
          alt={`${plan.duration} Extend warranty`}
          ratio="88 / 91"
          src={require("images/icons/extend_icon.svg")}
          width="22px"
        />
        <Typography fontSize="12px" fontWeight="700">
          {plan.duration}
        </Typography>
        <Typography fontSize="12px">Protection Plan</Typography>
        <Typography data-cy="warranty-addon-plan-price" fontSize="12px">
          {to_currency(plan.price, { precision: 2 })}
        </Typography>
      </Plan>
    </Box>
  )
}

WarrantyPlanItem.propTypes = {
  handleClick: PropTypes.func,
  plan: PropTypes.object,
  selected: PropTypes.bool,
  tabIndex: PropTypes.number,
}

const InternalWarrantPlans = ({ item, itemWarranty, setWarranty }) => {
  return (
    <Flex
      alignItems="center"
      flexDirection={{ _: "column", tablet: "row" }}
      gap={{ _: "18px", tablet: "26px" }}
    >
      <FocusScope>
        <Flex
          alignItems="flex-end"
          gap={{ _: "18px", tablet: 3 }}
          justifyContent="center"
          pb={{ tablet: 2 }}
          width={{ _: "100%", tablet: "auto" }}
        >
          {item.warranty_plans.map((plan, index) => {
            const selected = plan.value === itemWarranty?.addon_code

            return (
              <WarrantyPlanItem
                handleClick={() => {
                  if (selected) {
                    setWarranty({})
                  } else {
                    if (typeof FS !== "undefined") {
                      FS.event("Extend Cart Plan Selected", {
                        planId: plan.value,
                      })
                    }
                    setWarranty(plan)
                  }
                }}
                key={`warranty-plan-${plan.value}`}
                plan={plan}
                selected={selected}
                tabIndex={selected || (!itemWarranty && index === 0) ? 0 : -1}
              />
            )
          })}
        </Flex>
      </FocusScope>
      <Box>
        <PlanInfo>
          {planItems.map((item, i) => (
            <PlanItemWrapper
              key={`plan-item-${i}`}
              order={item.order}
              width={{ _: item.width, tablet: "auto" }}
            >
              <Image
                alt={item.alt}
                aria-hidden
                height="17px"
                src={require(`images/cart/warranty/${item.img}`)}
                width="17px"
              />
              <PlansText>{item.title}</PlansText>
            </PlanItemWrapper>
          ))}
        </PlanInfo>
        <Box mt={{ _: "10px", tablet: 0 }}>
          <Typography
            display={{ _: "inline", tablet: "block" }}
            fontSize="12px"
            fontWeight="300"
            letterSpacing="-0.02em"
            lineHeight="22px"
          >
            Offered and sold by Extend.{" "}
          </Typography>
          <PlansLink
            href="https://customers.extend.com/plan_details/A0-AAJEW-2y"
            underline
          >
            Learn more about the plans
          </PlansLink>
        </Box>
      </Box>
    </Flex>
  )
}

const mapStateToProps = (state, ownProps) => {
  const itemWarranty = ownProps.item.addons.find(
    (a) => a.addon_type === "WARRANTY"
  )
  return {
    itemWarranty,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const itemId = ownProps.item.id

  return {
    setWarranty: (plan) => {
      dispatch(setWarranty(itemId, plan))
    },
  }
}

InternalWarrantPlans.propTypes = {
  item: PropTypes.object,
  itemWarranty: PropTypes.object,
  setWarranty: PropTypes.func,
}

const WarrantPlans = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalWarrantPlans)

export default WarrantPlans
