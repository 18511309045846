import { MediaFlex } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"

import AddonDesktopItem from "./addon-desktop-item"

const InternalAddon = ({
  addons,
  deleteExclusiveAddon,
  exclusiveAddonsRef,
}) => {
  if (addons.length === 0) return null

  return (
    <MediaFlex
      flexDirection="column"
      gap={2}
      greaterThanOrEqual="tablet"
      ref={exclusiveAddonsRef}
    >
      {addons.length > 0 &&
        addons.map((addon, index) => (
          <AddonDesktopItem
            addon={addon}
            data-cy={`cart-addon-desktop-${index}-${addon.addon_code}`}
            deleteExclusiveAddon={deleteExclusiveAddon}
            id={"addon_item_" + addon.id}
            index={index}
            key={`d_addon-${addon.id}`}
          />
        ))}
    </MediaFlex>
  )
}

const mapStateToProps = (state) => {
  return {
    addons: state.cart.addons.filter(
      (a) => a.addon_type === "BUNDLE" || a.addon_type === "GIFT_CARD"
    ),
  }
}

const mapDispatchToProps = () => {
  return {}
}

InternalAddon.propTypes = {
  addons: PropTypes.array,
  deleteExclusiveAddon: PropTypes.func,
  exclusiveAddonsRef: PropTypes.func,
}

const Addon = connect(mapStateToProps, mapDispatchToProps)(InternalAddon)

export default Addon
