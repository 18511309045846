import { MediaBox, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"

import {
  AddonSlider,
  AddonSliderWrapper,
  TitleDivider,
  TitleDividerText,
} from "../components"

import AddonMobile from "./addon-mobile"

const InternalExclusiveAddons = ({
  addons,
  disableActions,
  setAddonInModal,
  toggleAddonModal,
  toggleDisableActions,
}) => {
  if (!addons || addons.length < 1) return ""

  return (
    <MediaBox lessThan="tablet" mb="20px">
      <TitleDivider mb="10px">
        <TitleDividerText>Exclusive Add-on Deal</TitleDividerText>
      </TitleDivider>
      <Typography
        fontSize="15px"
        fontWeight="500"
        lineHeight="22px"
        textAlign="center"
      >
        Save up to 70% on these Exclusive Add-on items!
      </Typography>
      <AddonSliderWrapper m="0" slide={addons.length > 2}>
        <AddonSlider
          {...(addons.length > 2
            ? {
                showScroll: "true",
              }
            : {
                justifyContent: "center",
                mr: 0,
                px: "10px",
              })}
        >
          {addons.map((addon) => (
            <AddonMobile
              addon={addon}
              disableActions={disableActions}
              key={`exclusive-${addon.code}-${addon.id}`}
              setAddonInModal={setAddonInModal}
              toggleAddonModal={toggleAddonModal}
              toggleDisableActions={toggleDisableActions}
            />
          ))}
        </AddonSlider>
      </AddonSliderWrapper>
    </MediaBox>
  )
}

const mapStateToProps = (state) => {
  return {
    addons: state.cart.premium_gift_offers,
  }
}

const mapDispatchToProps = () => {
  return {}
}

InternalExclusiveAddons.propTypes = {
  addons: PropTypes.array,
  disableActions: PropTypes.object,
  setAddonInModal: PropTypes.func,
  toggleAddonModal: PropTypes.func,
  toggleDisableActions: PropTypes.func,
}

const ExclusiveAddons = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalExclusiveAddons)

export default ExclusiveAddons
