import { faMinus } from "@jewlr/pro-light-svg-icons/faMinus"
import { faPlus } from "@jewlr/pro-light-svg-icons/faPlus"
import { Expand } from "@jewlr/storybook/animate"
import { Box, Button, FontAwesomeIcon, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"

const Accordion = styled(Box)`
  ${(props) =>
    props.subAccordion
      ? `
    border-bottom: 0.5px solid ${props.theme.colors.grey[30]};
  `
      : `
    border-top: 0.5px solid ${props.theme.colors.grey[30]};
    ${props.theme.mediaQueries.tablet`
      padding-left: 16px;
      padding-right: 16px;
    `}
  `}
  ${(props) => props.theme.mediaQueries.tablet`
    padding-left: 0;
    padding-right: 0;
  `}
`
const AccordionHeading = styled(Button).attrs({
  variant: "text",
})`
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${(props) => (props.subAccordion ? "12px" : "16px")};
  padding-top: ${(props) => (props.subAccordion ? "12px" : "16px")};
  width: 100%;
  &:focus {
    outline-offset: 0;
  }
`
const AccordionTitle = styled(Typography).attrs({
  as: "span",
})`
  color: ${(props) => props.theme.colors.primary};
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  text-transform: capitalize;
  ${(props) => props.theme.mediaQueries.tablet`
    color: ${props.theme.colors.grey[65]};
  `}
  ${(props) =>
    props.subAccordion &&
    props.theme.mediaQueries.tablet`
    font-size: 12px;
    line-height: 14px;
    color: ${props.theme.colors.black};
  `}
  ${(props) =>
    !props.subAccordion &&
    props.theme.mediaQueries.tablet`
    color: ${props.theme.colors.primary};
  `}
`

const FooterAccordion = ({ children, id, subAccordion, title }) => {
  const [show, toggleShow] = useState(false)

  return (
    <Accordion subAccordion={subAccordion}>
      <Box as={subAccordion ? "h6" : "h5"} m="0" p="0">
        <AccordionHeading
          aria-controls={id}
          aria-expanded={show}
          onClick={() => toggleShow(!show)}
        >
          <AccordionTitle subAccordion={subAccordion}>{title}</AccordionTitle>
          <FontAwesomeIcon
            color="primary"
            icon={show ? faMinus : faPlus}
            width="10px"
          />
        </AccordionHeading>
      </Box>
      <Expand id={id} show={show}>
        {children}
      </Expand>
    </Accordion>
  )
}

FooterAccordion.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  subAccordion: PropTypes.bool,
  title: PropTypes.string,
}

export default FooterAccordion
