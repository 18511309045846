import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import { setGreetingCard } from "areas/cart/store/actions"

export default function withGreetingCards(WrappedComponent) {
  const InternalGreetingCards = (props) => {
    const [selectedGreetingCard, setCard] = useState(props.greetingCard)

    useEffect(() => {
      setCard(props.greetingCard)
    }, [props.greetingCard])

    if (!props.cardOptions.length) return null

    return (
      <WrappedComponent
        handleGreetingCard={(card, callbacks) => {
          setCard(card)
          props.selectGreetingCard(card, callbacks)
        }}
        selectedGreetingCard={selectedGreetingCard}
        {...props}
      />
    )
  }

  const mapStateToProps = (state, ownProps) => {
    const cardOptions = ownProps.item.greeting_card_options || []
    const greetingCard = ownProps.item.addons.find(
      (a) => a.addon_type === "GREETING_CARD"
    )
    return {
      cardOptions,
      greetingCard,
    }
  }

  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      selectGreetingCard: (gc, callbacks) => {
        dispatch(setGreetingCard(ownProps.item.id, gc, callbacks))
      },
    }
  }

  InternalGreetingCards.propTypes = {
    cardOptions: PropTypes.array,
    greetingCard: PropTypes.object,
    selectGreetingCard: PropTypes.func,
    selectedGreetingCard: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool,
    ]),
  }

  const GreetingCards = connect(
    mapStateToProps,
    mapDispatchToProps
  )(InternalGreetingCards)

  return GreetingCards
}
