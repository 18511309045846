import { Image, MediaBox } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"

import { Panel } from "areas/cart/components"
import { jewlr_t, IS_JEWLR } from "helpers/application"
import { freeGiftPromoEnabled } from "helpers/holiday"

import Help from "./help-footer"
import OrderSummary from "./order-summary"
import PromoCode from "./promo-code"

const SidePanel = ({ refs }) => {
  return (
    <>
      <OrderSummary refs={refs} />
      <MediaBox
        {...(freeGiftPromoEnabled() && { greaterThanOrEqual: "tablet" })}
      >
        <Panel pb={{ _: 1, tablet: 3 }} pt={{ _: 0, tablet: 3 }}>
          <PromoCode />
        </Panel>
      </MediaBox>
      {sl.config.env !== "production" && IS_JEWLR() && (
        <MediaBox greaterThanOrEqual="tablet" mb={2}>
          <Link data-lc="side-panel" to="/">
            <Image
              alt={`${jewlr_t("terms.Personalize")} The Perfect Gift`}
              ratio="672 / 569"
              src={require("images/cart/ad-block.jpg")}
            />
          </Link>
        </MediaBox>
      )}
      {IS_JEWLR() && (
        <MediaBox greaterThanOrEqual="tablet">
          <Help />
        </MediaBox>
      )}
    </>
  )
}

SidePanel.propTypes = {
  refs: PropTypes.object,
}

export default SidePanel
