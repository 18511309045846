import { Box, Typography, Flex } from "@jewlr/storybook/core"
import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { IS_JEWLR } from "helpers/application"

import StartShopping from "../../jewlr/components/start-shopping"

const Container = styled(Flex)`
  align-items: center;
  color: white;
  flex-direction: column;
  justify-content: center;
  min-height: 200px;
  padding: 0 16px;
  text-align: center;
`
const ContinueShopping = styled(Box).attrs((props) => ({
  ...props.theme.cart.continueShopping,
}))``

const EmptyCart = () => {
  return (
    <Box contain>
      <Container>
        <Typography
          as="h2"
          color="black"
          data-cy="cart-empty-msg"
          fontSize="18px"
          letterSpacing="0"
          mb="20px"
        >
          Your shopping cart is currently empty
        </Typography>
        <ContinueShopping>
          <Link data-lc="empty-cart" to="/">
            Continue Shopping
          </Link>
        </ContinueShopping>
      </Container>
      {IS_JEWLR() && <StartShopping />}
    </Box>
  )
}
EmptyCart.propTypes = {}

export default EmptyCart
