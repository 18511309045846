import { Box, Flex, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { to_currency } from "helpers/number"

const VerticalBar = styled(Box)`
  background-color: #d9d9d9;
  height: 24px;
  margin: 0 4px;
  width: 1px;
  ${(props) => props.theme.mediaQueries.tablet`
    height: 35px;
    margin: 0 7px;
    width: 1.6px;
  `}
`

const Price = styled(Flex).attrs((props) => ({
  alignItems: "center",
  color: "sale",
  fontSize: { _: 30, tablet: 32 },
  fontWeight: 500,
  letterSpacing: "-0.04em",
  lineHeight: "30px",
  ...props,
}))``

const PriceDetail = styled(Typography).attrs((props) => ({
  fontSize: 12,
  fontWeight: 500,
  letterSpacing: "-0.04em",
  lineHeight: "14px",
  ...props,
}))``

const DollarSign = styled.span`
  font-size: 20px;
  letter-spacing: -0.04em;
`

const AddonPrice = ({ price, retail_price }) => (
  <Flex alignItems="center" justifyContent="center" mb={{ _: 1, tablet: 2 }}>
    <Price>
      <DollarSign>{sl.config.default_currency}</DollarSign>
      <span>{price}</span>
    </Price>
    <VerticalBar />
    {/* Retail Price */}
    <Flex
      alignItems="flex-start"
      flexDirection="column"
      justifyContent="center"
    >
      <PriceDetail>
        Retail: <strike>{to_currency(retail_price)}</strike>
      </PriceDetail>
      <PriceDetail color="sale">
        Save: {to_currency(retail_price - price)}
      </PriceDetail>
    </Flex>
  </Flex>
)

AddonPrice.propTypes = {
  price: PropTypes.number,
  retail_price: PropTypes.number,
}

export default AddonPrice
