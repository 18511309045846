import { Box, Flex, MediaBox, MediaFlex, Shimmer } from "@jewlr/storybook/core"
import React from "react"
import styled from "styled-components"

import { MainContainer, Panel, SidePanelWrapper, ShadowBox } from "./components"

const ImageBlock = styled(Box)`
  width: 20%;
`

const ItemInfoBlock = styled(Box)`
  width: 75%;
`

const ImageShimmer = styled(Shimmer)`
  height: 128px;
  margin-bottom: 15px;
  margin-top: 10px;
  width: 128px;
`

const SmallImageShimmer = styled(Shimmer)`
  height: 120px;
  margin-bottom: 65px;
  width: 120px;
`

const TextShimmer = styled(Shimmer)`
  height: ${(props) => (props.height ? props.height : "12px")};
  margin-bottom: 5px;
`

const CardBackground = styled(Box)`
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 14px 0 #ebebeb;
  margin: 0px 16px 0px;
  padding: 15px 10px 10px;
  position: relative;
`

const LineDivider = styled(Box)`
  border-top: 1px solid #f2f4f9;
  margin-bottom: 40px;
  margin-top: 40px;
`

const CtaShimmer = styled(Shimmer)`
  border-radius: 5px;
  height: 44px;
  margin-top: 16px;
  width: 100%;
`

const CartLoading = () => {
  return (
    <>
      {[...Array(2)].map((_, index) => (
        <div key={`cartLoading-mobile-item-${index}`}>
          <MediaBox lessThan="tablet">
            <CardBackground>
              <TextShimmer mb="0" ml="15px" width="180px" />
              <br />
              <Box borderTop="1px solid #f2f4f9">
                <Flex
                  borderBottom="1px solid #f3f3f3"
                  m="18px 0 20px"
                  px="10px"
                >
                  <div>
                    <SmallImageShimmer />
                  </div>
                  <div>
                    <div>
                      <TextShimmer
                        height="24px"
                        mb="5px"
                        ml="15px"
                        width="50px"
                      />
                      <br />
                      <TextShimmer mb="5px" ml="15px" width="120px" />
                      <br />
                      <TextShimmer mb="5px" ml="15px" width="120px" />
                    </div>
                  </div>
                </Flex>

                <Flex justifyContent="center">
                  <TextShimmer mb="5px" width="180px" />
                </Flex>
              </Box>
            </CardBackground>
            {index === 0 && <LineDivider />}
          </MediaBox>
        </div>
      ))}
      <MediaBox greaterThanOrEqual="tablet">
        <Flex gap={3}>
          <MainContainer>
            <MediaFlex
              flexDirection="column"
              gap={2}
              greaterThanOrEqual="tablet"
            >
              {[...Array(2)].map((_, index) => (
                <ShadowBox key={`cartLoading-desktop-item-${index}`}>
                  <Flex>
                    <ImageBlock>
                      <ImageShimmer />
                    </ImageBlock>
                    <ItemInfoBlock ml="auto">
                      <Flex justifyContent="space-between">
                        <div>
                          <div>
                            <TextShimmer mx="0" my="5px" width="50px" />
                            <TextShimmer mx="0" my="5px" width="50px" />
                          </div>
                        </div>
                        <div>
                          <TextShimmer mx="0" my="5px" width="50px" />
                          <TextShimmer
                            height="24px"
                            mx="0"
                            my="5px"
                            width="50px"
                          />
                          <TextShimmer mx="0" my="5px" width="50px" />
                        </div>
                      </Flex>
                      <div>
                        <br />
                        <TextShimmer width="120px" />
                        <br />
                        <div>
                          <TextShimmer width="180px" />
                        </div>
                      </div>
                    </ItemInfoBlock>
                  </Flex>
                </ShadowBox>
              ))}
            </MediaFlex>
          </MainContainer>
          <SidePanelWrapper>
            <Panel>
              <TextShimmer height="24px" mb="20px" width="60%%" />
              <Flex justifyContent="space-between" my="20px">
                <TextShimmer height="14px" mx="0" my="5px" width="100px" />
                <TextShimmer height="14px" mx="0" my="5px" width="70px" />
              </Flex>
              <Flex justifyContent="space-between" mb={1}>
                <TextShimmer height="14px" mx="0" my="5px" width="30px" />
                <TextShimmer height="14px" mx="0" my="5px" width="50px" />
              </Flex>
              <Flex justifyContent="space-between" mb={1}>
                <TextShimmer height="14px" mx="0" my="5px" width="30px" />
                <TextShimmer height="14px" mx="0" my="5px" width="50px" />
              </Flex>
              <Flex justifyContent="space-between" my="20px">
                <TextShimmer height="14px" mx="0" my="5px" width="70px" />
                <TextShimmer height="14px" mx="0" my="5px" width="85px" />
              </Flex>
              <CtaShimmer />
            </Panel>
          </SidePanelWrapper>
        </Flex>
      </MediaBox>
    </>
  )
}

export default CartLoading
