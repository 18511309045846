import { Box } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { ItemTitle, ItemAction } from "areas/cart/components"

import LineItemDetails from "./line-item-details"

const ItemActionContainer = styled.div`
  color: ${(props) => props.theme.colors.link};
  ${(props) => props.theme.cart.itemActionContainer}
`

const InfoBox = styled(Box).attrs((props) => ({
  ...props.theme.cart.lineItemInfo,
}))``

const LineItemInfo = ({ item, toggleRemoveItemDialog }) => {
  return (
    <InfoBox>
      <ItemTitle as="h3" data-cy="cart-line-item-info-title" mb={2}>
        {item.title}
      </ItemTitle>
      {item.in_stock && (
        <>
          <ItemActionContainer>
            <ItemAction
              data-cy={`cart-modify-btn-${item.style_code}`}
              data-lc="line-item-info"
              forwardedAs={Link}
              to={item.modify_url}
            >
              Modify
            </ItemAction>
            <ItemAction
              data-cy={`cart-remove-btn-${item.style_code}`}
              onClick={() => toggleRemoveItemDialog(true)}
            >
              Remove
            </ItemAction>
          </ItemActionContainer>
          <LineItemDetails item={item} />
        </>
      )}
    </InfoBox>
  )
}

LineItemInfo.propTypes = {
  item: PropTypes.object,
  toggleRemoveItemDialog: PropTypes.func,
}

export default LineItemInfo
