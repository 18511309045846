import { faChevronDown } from "@jewlr/pro-regular-svg-icons/faChevronDown"
import { faChevronUp } from "@jewlr/pro-regular-svg-icons/faChevronUp"
import { faMinus } from "@jewlr/pro-regular-svg-icons/faMinus"
import { faPlus } from "@jewlr/pro-regular-svg-icons/faPlus"
import { faSearchPlus } from "@jewlr/pro-regular-svg-icons/faSearchPlus"
import { faSpinner } from "@jewlr/pro-regular-svg-icons/faSpinner"
import {
  Box,
  Button,
  Flex,
  FontAwesomeIcon,
  Image,
} from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import scroll from "scroll"
import scrollDoc from "scroll-doc"
import styled from "styled-components"

import { addAddon, removeAddon } from "areas/cart/store/actions"

import AddonPrice from "./addon-price"

const AddBtn = styled(Button).attrs({
  alignItems: "center",
  borderRadius: "5px",
  display: "flex",
  fontSize: 14,
  fontWeight: 500,
  height: "28px",
  justifyContent: "center",
  letterSpacing: "-0.04em",
  lineHeight: "14px",
  mb: 1,
  mx: "auto",
  padding: 0,
  variant: "primary",
  width: "100px",
})``

const QtyBox = styled(Flex).attrs({
  alignItems: "center",
  height: "28px",
  justifyContent: "center",
  mb: 1,
})``

const QtyBtn = styled(Button).attrs((props) => ({
  alignItems: "center",
  bg: props.theme.colors.grey4,
  borderBottom: `1px solid ${props.theme.colors.grey20}`,
  borderTop: `1px solid ${props.theme.colors.grey20}`,
  height: "100%",
  justifyContent: "center",
  padding: 0,
  width: "38px",
  ...props,
}))`
  &:focus {
    border: 2px solid ${(props) => props.theme.colors.primary};
    outline: none;
  }
`

const QtyIncrementBtn = styled(QtyBtn).attrs((props) => ({
  borderBottomRightRadius: "5px",
  borderRight: `1px solid ${props.theme.colors.grey20}`,
  borderTopRightRadius: "5px",
}))``

const QtyDecrementBtn = styled(QtyBtn).attrs((props) => ({
  borderBottomLeftRadius: "5px",
  borderLeft: `1px solid ${props.theme.colors.grey20}`,
  borderTopLeftRadius: "5px",
}))``

const AddonCount = styled(Flex).attrs((props) => ({
  alignItems: "center",
  bg: "white",
  borderBottom: `1px solid ${props.theme.colors.grey20}`,
  borderTop: `1px solid ${props.theme.colors.grey20}`,
  fontSize: 12,
  fontWeight: 500,
  height: "100%",
  letterSpacing: "-0.02em",
  lineHeight: "14px",
  px: "10px",
  py: "6px",
}))``

const StyledIcon = styled(FontAwesomeIcon).attrs((props) => ({
  color: props.theme.colors.grey65,
  width: "14px",
  ...props,
}))`
  vertical-align: text-bottom;
`

const DetailsBtn = styled(Button).attrs({
  alignItems: "center",
  color: "primary",
  display: "flex",
  fontSize: 15,
  fontWeight: 500,
  justifyContent: "center",
  letterSpacing: "-0.04em",
  lineHeight: "14px",
  mb: 3,
  mx: "auto",
  position: "relative",
  variant: "text",
})`
  ${({ active, theme }) =>
    `
    &:before,
    &:after {
      border: 16px solid transparent;
      content: " ";
      display: none;
      height: 0;
      position: absolute;
      top: 50%;
      width: 0;
      ${theme.mediaQueries.tablet`
        display: block;
      `}
    }

    &:before {
      border-bottom-color: ${theme.colors.white};
    }

    &:after {
      border-bottom-color: ${
        active ? theme.colors.grey[4] : theme.colors.white
      };
    } 
    `}
`

const ImageButton = styled(Button).attrs({
  variant: "text",
})`
  position: relative;
  &:focus {
    outline-offset: -2px;
  }
`

const Magnify = styled(FontAwesomeIcon).attrs({
  color: "grey.30",
  fontSize: "16px",
})`
  bottom: 0;
  left: 0;
  margin: 6px;
  position: absolute;
`

const InternalExclusiveAddon = ({
  addonInDetail,
  item,
  disableActions,
  removeExclusiveAddon,
  addExclusiveAddon,
  setAddonInDetail,
  setAddonInModal,
  toggleAddonDetail,
  toggleAddonModal,
}) => {
  const removing =
    item.addons.findIndex((addon) => addon.id === disableActions.id) != -1 &&
    disableActions.action === "removing"
  const adding =
    disableActions.id === item.code && disableActions.action === "adding"
  const showDetails = addonInDetail?.code === item.code

  return (
    <Box
      data-cy="cart-exclusive-addon"
      key={`dexaddon-${item.id}-${item.code}`}
      letterSpacing="0.5px"
      minWidth={{ desktop: "20%", tablet: "33%" }}
      px={2}
      textAlign="center"
      width={{ desktop: "20%", tablet: "33%" }}
    >
      {/* Image */}
      <ImageButton
        onClick={() => {
          setAddonInModal(item)
          toggleAddonModal(true)
        }}
      >
        <Image
          alt={item.title}
          height="144px"
          src={item.images[0]}
          title={item.title}
          width="144px"
        />
        <Magnify icon={faSearchPlus} />
      </ImageButton>
      {/* Price */}
      <AddonPrice price={item.price} retail_price={item.retail_price} />

      {/* Add to Carts | Details */}
      {item.addons.length === 0 ? (
        <AddBtn
          loading={adding}
          onClick={() => {
            if (disableActions.id === "") {
              addExclusiveAddon(item.code, item.type)
            }
          }}
        >
          Add to Cart
        </AddBtn>
      ) : (
        <QtyBox aria-label={`Added ${item.addons.length} addons to cart`}>
          <QtyDecrementBtn
            aria-label="Remove one from cart"
            onClick={() => {
              if (disableActions.id === "") {
                removeExclusiveAddon(item.addons[0].id)
              }
            }}
          >
            <StyledIcon icon={removing ? faSpinner : faMinus} spin={removing} />
          </QtyDecrementBtn>
          <AddonCount>{item.addons.length}</AddonCount>
          <QtyIncrementBtn
            aria-label="Add one to cart"
            onClick={() => {
              if (disableActions.id === "") {
                addExclusiveAddon(item.code, item.type)
              }
            }}
          >
            <StyledIcon icon={adding ? faSpinner : faPlus} spin={adding} />
          </QtyIncrementBtn>
        </QtyBox>
      )}
      <DetailsBtn
        active={showDetails}
        aria-controls="cart-exclusive-addon-details"
        aria-expanded={showDetails}
        onClick={() => {
          if (showDetails) {
            toggleAddonDetail(false)
            setAddonInDetail({})
          } else {
            toggleAddonDetail(true)
            setAddonInDetail(item)
          }
        }}
      >
        Details
        <FontAwesomeIcon
          icon={showDetails ? faChevronUp : faChevronDown}
          ml={1}
          style={{ verticalAlign: "text-bottom" }}
          width="8px"
        />
      </DetailsBtn>
    </Box>
  )
}

const mapStateToProps = (state) => {
  const addons = state.cart.addons.filter(
    (a) => a.addon_type === "BUNDLE" || a.addon_type === "GIFT_CARD"
  )
  const premiumGiftOffers = state.cart.premium_gift_offers.map((offer) => ({
    addons: addons.filter(({ addon_code }) => addon_code === offer.code),
    ...offer,
  }))

  return {
    premiumGiftOffers,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addExclusiveAddon: (addon_code, addon_type) => {
      dispatch(
        addAddon(
          addon_code,
          addon_type,
          "cart_desktop",
          ownProps.toggleDisableActions
        )
      )

      // scroll to exclusive addon section
      const { exclusiveAddonsRef } = ownProps.refs
      const page = scrollDoc()
      if (exclusiveAddonsRef) {
        const offset = exclusiveAddonsRef.offsetTop - 140
        scroll.top(page, offset)
      }
    },
    removeExclusiveAddon: (addon_id) => {
      dispatch(removeAddon(addon_id, ownProps.toggleDisableActions))
    },
  }
}

InternalExclusiveAddon.propTypes = {
  addExclusiveAddon: PropTypes.func,
  addonInDetail: PropTypes.object,
  disableActions: PropTypes.object,
  item: PropTypes.object,
  refs: PropTypes.object,
  removeExclusiveAddon: PropTypes.func,
  setAddonInDetail: PropTypes.func,
  setAddonInModal: PropTypes.func,
  toggleAddonDetail: PropTypes.func,
  toggleAddonModal: PropTypes.func,
  toggleDisableActions: PropTypes.func,
}

const ExclusiveAddons = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalExclusiveAddon)

export default ExclusiveAddons
