import { Box, Button, Image, Typography } from "@jewlr/storybook/core"
import { Checkbox } from "@jewlr/storybook/form"
import { FocusScope } from "@react-aria/focus"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"

import withGreetingCards from "areas/cart/hoc/with-greeting-cards"
import { jewlr_t } from "helpers/application"
import { to_currency } from "helpers/number"
import { useNavigation } from "helpers/use-navigation"

import GreetingCardModal from "./greeting-card-modal"

const CardSlider = styled(Box)`
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  overflow: auto hidden;
  padding-bottom: 10px;
  ${(props) => props.theme.mediaQueries.tablet`
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    overflow: hidden;
  `}
`
const CardItem = styled(Button).attrs({
  variant: "text",
})`
  border: 2px solid
    ${(props) => (props.selected ? props.theme.colors.selected : "transparent")};
  border-radius: 8px;
  display: block;
  flex: 0 0 30vw;
  padding: 10px;
  text-align: center;
  ${(props) => props.theme.mediaQueries.tablet`
    flex: 0 0 135px;
  `}
  &:focus {
    border: 2px solid ${(props) => props.theme.colors.primary};
    outline: none;
  }
  ${(props) =>
    props.selected &&
    `
    position: relative;
    &:after {
      bottom: -12px;
      content: url(${sl.config.jewlr_asset_url}images/icons/jewlr4-checkmark.svg);
      display: block;
      height: 22px;
      left: 50%;
      position: absolute;
      transform: scale(0.8) translateX(-50%);
      width: 22px;
    }
  `}
`

const GreetingCardItem = ({
  card,
  handleClick,
  selected,
  showPreview,
  styleCode,
  tabIndex,
}) => {
  const keyboardProps = useNavigation({ direction: "horizontal" })

  return (
    <CardItem
      aria-checked={selected}
      aria-label={`${card.title} greeting card`}
      key={`greeting-card-${card.addon_code}`}
      onClick={handleClick}
      role="radio"
      selected={selected}
      tabIndex={tabIndex}
      {...keyboardProps}
    >
      <Image
        alt={card.title}
        data-cy={`cart-line-item-greeting-card-${styleCode}`}
        ratio="240 / 240"
        src={card.image_url}
      />
      <Typography fontSize="12px" letterSpacing="-0.03em" lineHeight="14px">
        {card.title}
      </Typography>
      {showPreview && (
        <Typography color="primary" fontSize="12px" lineHeight="14px" underline>
          Preview
        </Typography>
      )}
    </CardItem>
  )
}

GreetingCardItem.propTypes = {
  card: PropTypes.object,
  handleClick: PropTypes.func,
  selected: PropTypes.bool,
  showPreview: PropTypes.bool,
  styleCode: PropTypes.string,
  tabIndex: PropTypes.number,
}

const GreetingCard = ({
  cardOptions,
  handleGreetingCard,
  item,
  selectedGreetingCard,
}) => {
  const [toggleGreetingCard, setToggleGreetingCard] = useState(
    !!selectedGreetingCard
  )
  const [toggleModal, setToggleModal] = useState(false)

  const selectedCardOption = cardOptions.find(
    (card) => card.addon_code === selectedGreetingCard?.addon_code
  )
  const { greeting_card_properties: greetingCardProps } =
    selectedGreetingCard?.custom_properties || {}

  const cardPrice = selectedCardOption
    ? selectedCardOption.price
    : cardOptions[0]?.price
  return (
    <>
      <Checkbox
        checked={toggleGreetingCard}
        data-cy={`cart-line-item-greeting-card-modal-${item.style_code}`}
        onChange={() => {
          if (toggleGreetingCard) {
            handleGreetingCard(null)
            setToggleGreetingCard(false)
          } else {
            handleGreetingCard(cardOptions[0])
            setToggleGreetingCard(true)
            setToggleModal(true)
          }
        }}
      >
        <Box ml="6px">
          Add A Greeting Card (
          {cardPrice > 0
            ? `+${to_currency(cardPrice, { precision: 2 })}`
            : "FREE"}
          )
        </Box>
      </Checkbox>
      <Box
        aria-labelledby={`cart-line-item-greeting-card-group-label-${item.id}`}
        ml="26px"
        role="radiogroup"
      >
        <Typography
          as="h5"
          fontSize="12px"
          fontWeight="400"
          id={`cart-line-item-greeting-card-group-label-${item.id}`}
          letterSpacing="-0.03em"
          lineHeight="12px"
          mb={2}
          mt={1}
        >
          Choose and {jewlr_t("terms.personalize")} your custom greeting card.
        </Typography>
        <FocusScope restoreFocus>
          <CardSlider
            data-cy={`cart-line-item-greeting-card-${item.style_code}`}
            mb={2}
          >
            {cardOptions.map((card, index) => {
              const selected =
                selectedGreetingCard?.addon_code === card.addon_code

              return (
                <GreetingCardItem
                  card={card}
                  handleClick={() => {
                    if (!selected) {
                      setToggleGreetingCard(true)
                      handleGreetingCard(card)
                    }
                    setToggleModal(true)
                  }}
                  key={`greeting-card-${card.addon_code}`}
                  selected={selected}
                  showPreview={selected && !!greetingCardProps?.message}
                  styleCode={item.style_code}
                  tabIndex={
                    selected || (!selectedGreetingCard && index === 0) ? 0 : -1
                  }
                />
              )
            })}
          </CardSlider>
        </FocusScope>
      </Box>
      <GreetingCardModal
        greetingCardProps={greetingCardProps}
        handleGreetingCard={handleGreetingCard}
        onClose={() => setToggleModal(false)}
        selectedCardOption={selectedCardOption}
        show={toggleModal}
      />
    </>
  )
}

GreetingCard.propTypes = {
  cardOptions: PropTypes.array,
  handleGreetingCard: PropTypes.func,
  item: PropTypes.object,
  selectedGreetingCard: PropTypes.object,
}

export default withGreetingCards(GreetingCard)
