import { Expand } from "@jewlr/storybook/animate"
import { Box, Carousel, MediaBox, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import { hideScrollbar } from "styles/utils"

import ExclusiveAddon from "./addon"

const AddonsCarousel = styled(Carousel)`
  ${hideScrollbar}
`

const SectionTitle = styled(Typography).attrs({
  as: "h4",
  fontSize: 26,
  fontWeight: 500,
  lineHeight: "30px",
  mb: 2,
})`
  text-transform: capitalize;
`

const InternalExclusiveAddons = ({
  disableActions,
  premiumGiftOffers,
  refs,
  setAddonInModal,
  toggleAddonModal,
  toggleDisableActions,
}) => {
  const [showAddonDetail, toggleAddonDetail] = useState(false)
  const [addonInDetail, setAddonInDetail] = useState({})

  return (
    <>
      <MediaBox greaterThanOrEqual="tablet" mt="34px" mx={2}>
        <SectionTitle>
          Save up to 70% on these Exclusive Add-on Deals!
        </SectionTitle>
        <Box contain>
          <AddonsCarousel
            displayArrows={{
              desktop: premiumGiftOffers?.length > 5 ? "flex" : "none",
              tablet: premiumGiftOffers?.length > 3 ? "flex" : "none",
            }}
            justifyContent={{
              desktop: premiumGiftOffers?.length > 5 ? "flex-start" : "center",
              tablet: premiumGiftOffers?.length > 3 ? "flex-start" : "center",
            }}
          >
            {premiumGiftOffers &&
              premiumGiftOffers.map((item, i) => {
                return (
                  <ExclusiveAddon
                    addonInDetail={addonInDetail}
                    disableActions={disableActions}
                    item={item}
                    key={`${item.code}-${i}`}
                    refs={refs}
                    setAddonInDetail={setAddonInDetail}
                    setAddonInModal={setAddonInModal}
                    toggleAddonDetail={toggleAddonDetail}
                    toggleAddonModal={toggleAddonModal}
                    toggleDisableActions={toggleDisableActions}
                  />
                )
              })}
          </AddonsCarousel>
        </Box>
      </MediaBox>
      <Expand id="cart-exclusive-addon-details" show={showAddonDetail}>
        <Box bg="grey.4" mb="28px" p={3}>
          <Typography fontSize={12} fontWeight={700} lineHeight="14px" mb="7px">
            {addonInDetail.title}
          </Typography>
          <Typography fontSize={12} fontWeight={500} lineHeight="14px">
            {addonInDetail.description}
          </Typography>
        </Box>
      </Expand>
    </>
  )
}

const mapStateToProps = (state) => {
  const addons = state.cart.addons.filter(
    (a) => a.addon_type === "BUNDLE" || a.addon_type === "GIFT_CARD"
  )
  const premiumGiftOffers = state.cart.premium_gift_offers.map((offer) => ({
    addons: addons.filter(({ addon_code }) => addon_code === offer.code),
    ...offer,
  }))

  return {
    premiumGiftOffers,
  }
}

const mapDispatchToProps = () => {
  return {}
}

InternalExclusiveAddons.propTypes = {
  disableActions: PropTypes.object,
  premiumGiftOffers: PropTypes.array,
  refs: PropTypes.object,
  setAddonInModal: PropTypes.func,
  toggleAddonModal: PropTypes.func,
  toggleDisableActions: PropTypes.func,
}

const ExclusiveAddons = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalExclusiveAddons)

export default ExclusiveAddons
