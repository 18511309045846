import { faShieldCheck as lightShieldCheck } from "@jewlr/pro-light-svg-icons/faShieldCheck"
import { faMinus } from "@jewlr/pro-regular-svg-icons/faMinus"
import { faPlus } from "@jewlr/pro-regular-svg-icons/faPlus"
import { faChevronDown } from "@jewlr/pro-solid-svg-icons/faChevronDown"
import { faChevronUp } from "@jewlr/pro-solid-svg-icons/faChevronUp"
import { Expand } from "@jewlr/storybook/animate"
import {
  Box,
  Button,
  Flex,
  FontAwesomeIcon,
  MediaBox,
  MediaFlex,
  Typography,
} from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import { jewlr_t } from "helpers/application"

import WarrantyPlans from "./warranty-plans"

const Container = styled(Box).attrs((props) => ({
  borderTop: `1px solid ${props.theme.colors.grey[10]}`,
  px: { tablet: "12px" },
  py: { _: "18px", tablet: "14px" },
}))``

const ShieldIcon = styled(FontAwesomeIcon).attrs((props) => ({
  color: "primary",
  icon: lightShieldCheck,
  mr: 1,
  mt: "2px",
  ...props,
}))``

const Heading = styled(Typography).attrs({
  color: "primary",
  fontSize: "16px",
  fontWeight: "500",
  letterSpacing: "-0.03em",
  lineHeight: "20px",
})``

const Desc = styled(Typography).attrs({
  fontSize: { _: "13px", tablet: "12px" },
  fontWeight: { _: "500", tablet: "300" },
  letterSpacing: "-0.03em",
  lineHeight: { _: "16px", tablet: "20px" },
})``

const AddonToggle = styled(Typography).attrs((props) => ({
  color: "primary",
  fontSize: 12,
  fontWeight: 500,
  letterSpacing: "-0.03em",
  lineHeight: "14px",
  mr: "6px",
  ...props.theme.cart.addonToggle,
  ...props,
}))``

const InternalWarrantyAddon = ({ item }) => {
  if (!item.warranty_plans || item.warranty_plans.length === 0) return ""

  const [showWarranty, setShowWarranty] = useState(true)

  return (
    <Container data-cy="warranty-addon">
      <Box as="h4" m="0" p="0">
        <Button
          aria-controls={`cart-line-item-warranty-addon-content-${item.id}`}
          aria-expanded={showWarranty}
          aria-label="Add protection plan"
          display="block"
          id={`cart-line-item-warranty-addon-heading-${item.id}`}
          onClick={() => {
            setShowWarranty(!showWarranty)
            if (typeof FS !== "undefined") {
              FS.event("Extend Cart Offers Toggled")
            }
          }}
          variant="text"
          width="100%"
        >
          <Flex alignItems="baseline" justifyContent="space-between">
            <Flex>
              <ShieldIcon />
              <MediaBox lessThan="tablet" textAlign="left">
                <Heading>Add Protection Plan</Heading>
                <Desc mt="10px">
                  Select a plan to protect your {jewlr_t("terms.jewelry")} from
                  accidental damage.
                </Desc>
              </MediaBox>
              <MediaBox greaterThanOrEqual="tablet" textAlign="left">
                <Heading>Protect Your {jewlr_t("terms.Jewelry")}</Heading>
                <Desc>
                  Select a plan to protect your {jewlr_t("terms.jewelry")} from
                  accidental damage.
                </Desc>
              </MediaBox>
            </Flex>
            <MediaFlex lessThan="tablet">
              <FontAwesomeIcon
                color="primary"
                fontSize="14px"
                icon={showWarranty ? faMinus : faPlus}
              />
            </MediaFlex>
            <MediaFlex alignItems="center" greaterThanOrEqual="tablet">
              <AddonToggle>Add Protection Plan</AddonToggle>
              <FontAwesomeIcon
                color="primary"
                icon={showWarranty ? faChevronUp : faChevronDown}
                width="9px"
              />
            </MediaFlex>
          </Flex>
        </Button>
      </Box>
      <Expand
        aria-labelledby={`cart-line-item-warranty-addon-content-${item.id}`}
        id={`cart-line-item-warranty-addon-heading-${item.id}`}
        show={showWarranty}
      >
        <Box ml={{ tablet: 3 }} mt={{ _: "10px", tablet: 2 }}>
          <WarrantyPlans item={item} />
        </Box>
      </Expand>
    </Container>
  )
}

InternalWarrantyAddon.propTypes = {
  item: PropTypes.object,
}

const mapStateToProps = () => {
  return {}
}

const WarrantyAddon = connect(mapStateToProps)(InternalWarrantyAddon)

export default WarrantyAddon
