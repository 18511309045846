import { faLockAlt as lightLockAlt } from "@jewlr/pro-light-svg-icons/faLockAlt"
import {
  Box,
  Button,
  Flex,
  FontAwesomeIcon,
  MediaFlex,
  Typography,
} from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import { checkAppleGooglePay } from "areas/cart/store/actions"
import { setPaymentMethod } from "areas/checkout/store/actions"
import { submitSecureCheckout } from "helpers/cart"
import useWindowDimensions from "helpers/window-dimensions"

const Wrapper = styled(Box)`
  ${(props) =>
    props.fixed &&
    `
    background: ${props.theme.colors.white};
    box-shadow: 0 -4px 4px 0 rgba(0, 0, 0, 0.15);
    bottom: 0;
    left: 0;
    padding: 12px 16px 10px 16px;
    position: fixed;
    width: 100%;
    z-index: 2;
    ${props.theme.mediaQueries.tablet`
      box-shadow: none;
      padding: 0;
      position: static;
      width: auto;
    `}
  `}
`

const Subtotal = styled(Typography).attrs((props) => ({
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "21px",
  ...props,
}))``

const CheckoutBtn = styled(Button).attrs((props) => ({
  alignItems: "center",
  borderRadius: 5,
  display: "flex",
  fontSize: "18px",
  fontWeight: 700,
  height: "48px",
  justifyContent: "center",
  letterSpacing: "0.5px",
  lineHeight: "16px",
  variant: "checkout",
  width: "100%",
  ...props,
}))``

const CheckoutButton = ({ dispatch, refs, summary }) => {
  const [loading, setLoading] = useState(false)
  const [fixedBtn, setFixedBtn] = useState(false)
  const { isMobile } = useWindowDimensions()
  const cancelled = useRef(false)

  useEffect(() => {
    if (!cancelled.current) {
      const requestTick = () => {
        if (refs.cartSummaryRef?.current) {
          const rect = refs.cartSummaryRef.current.getBoundingClientRect()
          if (!rect) return

          const fixed = rect.bottom <= 0 || rect.bottom >= window.innerHeight
          setFixedBtn(fixed)
        }
      }

      const checkoutScrollTick = () => {
        if (isMobile) {
          window.requestAnimationFrame(requestTick)
        } else {
          setFixedBtn(false)
        }
      }

      checkoutScrollTick()
      window.addEventListener("scroll", checkoutScrollTick, false)
      return () => {
        window.removeEventListener("scroll", checkoutScrollTick, false)
      }
    }
  }, [fixedBtn, isMobile, refs.cartSummaryRef.current])

  useEffect(() => {
    return () => (cancelled.current = true)
  }, [])

  const secureCheckout = () => {
    setLoading(true)
    submitSecureCheckout(null, dispatch, {
      callback: () => {
        if (!cancelled.current) {
          setLoading(false)
        }
        dispatch(setPaymentMethod(null))
      },
    })
  }

  return (
    <Wrapper fixed={fixedBtn}>
      {fixedBtn && (
        <MediaFlex
          alignItems="center"
          justifyContent="space-between"
          lessThan="tablet"
          mb={1}
        >
          <Subtotal>Est. Total:</Subtotal>
          <Flex>
            {summary.order_total !== summary.total && (
              <Subtotal color="grey.30" fontWeight="500" mr="6px">
                <strike>{summary.order_total}</strike>
              </Subtotal>
            )}
            <Subtotal>{summary.total}</Subtotal>
          </Flex>
        </MediaFlex>
      )}
      <CheckoutBtn
        data-cy={`cart-checkout-btn-${isMobile ? "mobile" : "desktop"}`}
        loading={loading}
        onClick={() => secureCheckout()}
        startIcon={
          <FontAwesomeIcon
            fontSize=".875em"
            icon={lightLockAlt}
            style={{ mr: "6px", verticalAlign: "initial" }}
          />
        }
      >
        Checkout
      </CheckoutBtn>
    </Wrapper>
  )
}

CheckoutButton.propTypes = {
  dispatch: PropTypes.func,
  refs: PropTypes.object,
  summary: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    summary: state.cart.summary,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkAppleGooglePay: () => {
      dispatch(checkAppleGooglePay())
    },
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutButton)
