import { Divider, MediaBox } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"

import LineItemDesktop from "./desktop"
import LineItemMobile from "./mobile"

const LineItems = ({ line_items }) => {
  return line_items.map((line_item, index) => (
    <React.Fragment key={`cartItem-${line_item.id}`}>
      <MediaBox greaterThanOrEqual="tablet">
        <LineItemDesktop item={line_item} />
      </MediaBox>
      <MediaBox lessThan="tablet">
        {index !== 0 && <Divider color="grey.20" my={3} />}
        <LineItemMobile item={line_item} />
      </MediaBox>
    </React.Fragment>
  ))
}

LineItems.propTypes = {
  line_items: PropTypes.array,
}

export default LineItems
