import { Expand } from "@jewlr/storybook/animate"
import { Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { Suspense } from "react"
import styled from "styled-components"

import { Box } from "components/common"
import { to_currency } from "helpers/number"

const Detail = styled(Typography).attrs({
  fontSize: 12,
  fontWeight: 500,
  mb: "2px",
})``

const StyledSpan = styled.span`
  a {
    color: ${(props) => props.theme.colors.primary};
    text-decoration: underline;
  }
`

const ItemDetails = ({ details, id, showDetails }) => (
  <>
    {typeof window !== "undefined" && (
      <Suspense fallback={<div />}>
        <Expand id={id} show={showDetails}>
          <Box
            pb={{ _: "20px", tablet: 0 }}
            pl={{ _: "15px", tablet: 0 }}
            pt={{ tablet: "4px" }}
          >
            <Details details={details} />
          </Box>
        </Expand>
      </Suspense>
    )}
  </>
)

export const Details = ({ details }) => {
  return (
    <>
      {typeof window !== "undefined" && (
        <Suspense fallback={<div />}>
          {details.map((detail) => (
            <React.Fragment key={`itemDetails-${detail.name}`}>
              {detail.values ? (
                <>
                  <Detail>{!!detail.label && `${detail.label}: `}</Detail>
                  {detail.values.map((value) => (
                    <Detail key={value}>- {value}</Detail>
                  ))}
                </>
              ) : (
                <Detail>
                  {!!detail.label && `${detail.label}: `}
                  <StyledSpan
                    dangerouslySetInnerHTML={{ __html: detail.value }}
                  />
                  {detail.price !== 0 && detail.name === "de" && (
                    <span> ({to_currency(detail.price)})</span>
                  )}
                  {detail.price !== 0 && detail.name !== "de" && (
                    <span> (+{to_currency(detail.price)})</span>
                  )}
                </Detail>
              )}
            </React.Fragment>
          ))}
        </Suspense>
      )}
    </>
  )
}

Details.propTypes = {
  details: PropTypes.array,
}

ItemDetails.propTypes = {
  details: PropTypes.array,
  id: PropTypes.string,
  showDetails: PropTypes.bool,
}

export default ItemDetails
