import { Box, Button, Confirmation, Flex, Image } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { emptyCart } from "areas/cart/store/actions"

import { LinkButton } from "./components"

const EmptyCartBtn = styled(Button).attrs((props) => ({
  color: "primary",
  fontSize: "14px",
  fontWeight: { _: 500, tablet: 300 },
  letterSpacing: "-0.02em",
  lineHeight: "20px",
  py: 2,
  textTransform: "none",
  underline: true,
  variant: "text",
  ...props.theme.cart.emptyCartBtn,
}))``

const InternalEmptyOutCart = ({ callEmptyCart }) => {
  const [showEmptyCartDialog, setShowEmptyCartDialog] = useState(false)

  const onCancel = () => {
    setShowEmptyCartDialog(false)
  }

  const onConfirm = () => {
    callEmptyCart()
    setShowEmptyCartDialog(false)
  }

  return (
    <Box contain mb={3} mt={{ _: 5, tablet: 4 }} textAlign="center">
      <Box>
        <LinkButton
          data-lc="empty-out-cart"
          fontWeight={{ _: 500, tablet: 300 }}
          forwardedAs={Link}
          to="/"
        >
          Continue Shopping
        </LinkButton>
      </Box>
      <EmptyCartBtn onClick={() => setShowEmptyCartDialog(true)}>
        <Flex alignItems="center" gap="6px">
          <Image
            alt="Order Summary"
            color="primary"
            height="12px"
            src={require("images/icons/cart_blue.svg")}
            width="16px"
          />
          Empty Cart
        </Flex>
      </EmptyCartBtn>

      <Confirmation
        cancelText="Cancel"
        confirmText="Yes"
        description="Are you sure you want to remove all items from your Cart?"
        onCancel={onCancel}
        onConfirm={onConfirm}
        show={showEmptyCartDialog}
        title="Empty Cart"
        variant="squircle"
      />
    </Box>
  )
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    callEmptyCart: () => {
      dispatch(emptyCart())
    },
  }
}

InternalEmptyOutCart.propTypes = {
  callEmptyCart: PropTypes.func,
}

const EmptyOutCart = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalEmptyOutCart)

export default EmptyOutCart
