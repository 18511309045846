import { faChevronDown } from "@jewlr/pro-regular-svg-icons/faChevronDown"
import { faChevronUp } from "@jewlr/pro-regular-svg-icons/faChevronUp"
import { Box, FontAwesomeIcon } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useState } from "react"

import { DetailBtn } from "areas/cart/components"

import ItemDetails from "../item-details"

const LineItemDetails = ({ item }) => {
  const [showDetails, toggleShowDetails] = useState(false)
  const itemDetailsId = `cart-item-details-desktop-${item.id}`

  return (
    <Box>
      <DetailBtn
        aria-controls={itemDetailsId}
        aria-expanded={showDetails}
        onClick={() => toggleShowDetails(!showDetails)}
      >
        View Item Details{" "}
        <FontAwesomeIcon
          icon={showDetails ? faChevronUp : faChevronDown}
          style={{ verticalAlign: "text-bottom" }}
          width="8px"
        />
      </DetailBtn>
      <ItemDetails
        details={item.view_item_details}
        id={itemDetailsId}
        showDetails={showDetails}
      />
    </Box>
  )
}

LineItemDetails.propTypes = {
  item: PropTypes.object,
}

export default LineItemDetails
