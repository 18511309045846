import {
  Box,
  Flex,
  MediaFlex,
  Shimmer,
  Typography,
} from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { fetchCartShare } from "app-store-v2/share/actions"
import { openModal } from "components/modal"
import { IS_JEWLR } from "helpers/application"

import { LinkButton } from "./components"

const Title = styled(Typography).attrs((props) => ({
  as: "h1",
  color: "black",
  fontSize: { _: "28px", tablet: "32px" },
  fontWeight: "700",
  lineHeight: "36px",
  mr: "6px",
  ...props,
}))``

const Container = styled(Box).attrs({
  mb: { _: "10px", tablet: 0 },
  mt: { tablet: 4 },
  textAlign: "center",
  width: "100%",
})``

const ItemCount = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.03em;
  line-height: 16px;
`

const ItemCountShimmer = styled(Shimmer)`
  height: 16px;
  width: 80px;
  ${(props) => props.theme.mediaQueries.tablet`
    width: 50px;
  `}
`

const Separator = styled.span`
  color: ${(props) => props.theme.colors.grey[65]};
  display: none;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.28px;
  line-height: 20px;
  margin: 0 4px;
  ${(props) => props.theme.mediaQueries.tablet`
    display: block;
  `}
`

const CartTitle = ({ cartData, cartLoaded, openShareModal }) => {
  const itemsCount = cartData.line_items?.length || 0

  return (
    <Container>
      <Flex
        alignItems={{ _: "baseline", tablet: "center" }}
        justifyContent="space-between"
      >
        <Flex alignItems="baseline">
          <Title>My Cart</Title>
          {cartLoaded ? (
            <>
              <ItemCount>
                ({itemsCount} item{itemsCount > 1 ? "s" : ""})
              </ItemCount>
              {IS_JEWLR() && (
                <LinkButton
                  display={{ tablet: "none" }}
                  ml={1}
                  onClick={() => openShareModal()}
                >
                  Share
                </LinkButton>
              )}
            </>
          ) : (
            <ItemCountShimmer />
          )}
        </Flex>
        {cartLoaded ? (
          <Flex alignItems="center">
            {IS_JEWLR() && (
              <MediaFlex greaterThanOrEqual="tablet">
                <LinkButton onClick={() => openShareModal()}>
                  Share Cart
                </LinkButton>
                <Separator>|</Separator>
              </MediaFlex>
            )}
            <LinkButton data-lc="cart-title" forwardedAs={Link} to="/">
              Continue Shopping
            </LinkButton>
          </Flex>
        ) : (
          <Shimmer height="20px" width={{ _: "100px", tablet: "170px" }} />
        )}
      </Flex>
    </Container>
  )
}

CartTitle.propTypes = {
  cartData: PropTypes.object,
  cartLoaded: PropTypes.bool,
  openShareModal: PropTypes.func,
}

const mapStateToProps = (state) => ({
  cartLoaded: state.cart.cartLoaded,
})

const mapDispatchToProps = (dispatch) => {
  return {
    openShareModal: () => {
      dispatch(fetchCartShare())
      openModal("#share-modal")
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartTitle)
