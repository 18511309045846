import { faFlowerTulip } from "@jewlr/pro-light-svg-icons/faFlowerTulip"
// import { faGift } from "@jewlr/pro-light-svg-icons/faGift"
//import { faBoxHeart } from "@jewlr/pro-regular-svg-icons"
import {
  Box,
  FontAwesomeIcon,
  MediaFlex,
  Typography,
} from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { IS_JEWLR, jewlr_t } from "helpers/application"

const Wrapper = styled(Box)`
  margin-bottom: 16px;
  margin-top: 14px;
  text-align: right;
  ${(props) => props.theme.mediaQueries.tablet`
    margin-top: 12px;
  `}
`
const ShipMethod = styled(Typography).attrs((props) => ({
  color: "black",
  fontSize: "14px",
  fontWeight: "500",
  mb: "2px",
  ...props,
}))`
  ${(props) => props.theme.mediaQueries.tablet`
    white-space: nowrap;
  `}
  ${(props) => props.theme.cart.shipMethod}
`

const ShipDate = styled.span`
  font-style: italic;
  ${(props) => props.theme.mediaQueries.tablet`
    font-style: normal;
  `}
`

const LineItemShipping = ({ item }) => {
  // const forHer = item.product_gender === "female"

  return (
    <Wrapper>
      {item.holiday_availability && (
        <MediaFlex
          alignItems="center"
          greaterThanOrEqual="tablet"
          justifyContent="flex-end"
          mb={1}
        >
          <FontAwesomeIcon
            color="sale"
            fontSize="14px"
            icon={faFlowerTulip}
            mr="5px"
          />
          {/* <FontAwesomeIcon
            icon={faGift}
            style={{ verticalAlign: "text-top" }}
          />{" "} */}
          {/* <Box
            as="img"
            height="16px"
            mr="1px"
            src={require("images/icons/doublehearts_bold.svg")}
            verticalAlign="text-top"
            width="auto"
          />{" "} */}
          <ShipMethod
            color="sale"
            fontSize="16px"
            fontWeight="700"
            letterSpacing="-0.03em"
            mb={0}
          >
            Arrives Before {sl.config.guaranteed_holiday}
            {/* Arrives{" "}
          {new Date(item.min_delivery_date).getDate() ===
          new Date(sl.config.guaranteed_receive_date).getDate()
            ? "By"
            : "Before"}{" "}
          {sl.config.guaranteed_holiday} */}
          </ShipMethod>
        </MediaFlex>
      )}
      {item.delivery_dates && item.delivery_dates.length > 0 ? (
        item.delivery_dates.map((d) => (
          <ShipMethod
            color={d.type.includes("FREE") ? "black" : "greenDarker"}
            data-cy={`itemShipping-${d.type}`}
            key={`itemShipping-${d.type}`}
          >
            <strong>{d.type}</strong> <ShipDate>{d.date}</ShipDate>
          </ShipMethod>
        ))
      ) : item.delivery_date_range ? (
        <ShipMethod color="black">
          {IS_JEWLR() ? "Free" : "Standard"} {jewlr_t("terms.Shipping")}{" "}
          <ShipDate>
            {item.delivery_date_range[0]}-{item.delivery_date_range[1]}
          </ShipDate>
        </ShipMethod>
      ) : (
        <ShipMethod>
          Ships by <ShipDate>{item.est_ship_date}</ShipDate>
        </ShipMethod>
      )}
    </Wrapper>
  )
}

LineItemShipping.propTypes = {
  item: PropTypes.object,
}

export default LineItemShipping
