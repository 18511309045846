import { Box, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"

import { RetailPrice } from "areas/cart/components"
import { IS_JEWLR } from "helpers/application"
import { to_currency } from "helpers/number"

const Price = ({ dataCyType, item }) => {
  const onSale = item.on_sale
  return (
    <Box>
      {IS_JEWLR() ? (
        <>
          {onSale && (
            <Typography
              bold
              color="sale"
              fontSize={{ _: 10, tablet: 16 }}
              letterSpacing="2px"
              lineHeight={{ _: "1.5", tablet: "1" }}
            >
              SALE
            </Typography>
          )}
          <Typography
            color={onSale ? "sale" : "black"}
            data-cy={`cart-line-item-price-${dataCyType}-${item.style_code}`}
            fontSize={{ _: "30px", tablet: "32px" }}
            fontWeight="300"
            lineHeight="1"
          >
            {to_currency(item.price)}
          </Typography>

          <RetailPrice
            color={onSale ? "sale" : "black"}
            fontWeight={{ _: "300", tablet: "500" }}
            lineHeight={{
              _: "1.5",
              tablet: "normal",
            }}
          >
            RETAIL {to_currency(item.retail_price)}
          </RetailPrice>
        </>
      ) : (
        <Typography
          color={onSale ? "sale" : "black"}
          data-cy={`cart-line-item-price-${dataCyType}-${item.style_code}`}
          fontSize={{ _: "30px", tablet: "32px" }}
          fontWeight="300"
          lineHeight="1"
        >
          {to_currency(item.price)}
        </Typography>
      )}
    </Box>
  )
}

Price.propTypes = {
  dataCyType: PropTypes.string,
  item: PropTypes.object,
}

export default Price
