import {
  Box,
  Typography,
  Button,
  Image,
  FontAwesomeIcon,
} from "@jewlr/storybook/core"
import { Link } from "react-router-dom"
import styled from "styled-components"

export const CardProductImageContainer = styled(Box)`
  height: 100%;
  position: relative;
  ${(props) =>
    props.outOfStock &&
    `
    &:after {
      background: ${props.theme.colors.black};
      content: "";
      height: 100%;
      left: 0;
      opacity: 0.1;
      position: absolute;
      top: 0;
      width: 100%;
    }
  `}
`

export const CardProductImage = styled(Image)`
  height: 120px;
  max-width: 120px;
  width: 120px;
  ${(props) => props.theme.mediaQueries.tablet`
    height: 128px;
    max-width: 128px;
    width: 128px;
  `}
`
export const CardRemove = styled(Button).attrs({
  variant: "text",
})`
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.grey[65]};
  border-radius: 50%;
  color: ${(props) => props.theme.colors.grey[65]};
  display: block;
  font-size: 16px;
  height: 28px;
  justify-content: center;
  left: -12px;
  line-height: 1;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  padding: 5px;
  position: absolute;
  text-align: center;
  top: -12px;
  width: 28px;
  z-index: 1;
  :active {
    background-color: ${(props) => props.theme.colors.grey[10]};
  }
`
export const Card = styled(Box)`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 12px;
  box-shadow: 0 4px 14px 0 #ebebeb;
  padding: 15px 10px 10px;
  position: relative;
`
export const CardTitle = styled(Link)`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey5};
  display: block;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1.3;
  margin-bottom: 12px;
  margin-left: 15px;
  margin-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const CardItemInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0;
`

export const ItemTitle = styled(Typography).attrs((props) => ({
  color: "black",
  fontSize: { _: "14px", tablet: "16px" },
  mb: 2,
  ...props.theme.cart.itemTitle,
  ...props,
}))`
  letter-spacing: 0.5px;
`
export const ItemAction = styled(Button).attrs(() => ({
  color: "link",
  display: "block",
  variant: "text",
}))`
  font-size: 14px;
  font-weight: 300;
  :hover {
    color: ${(props) => props.theme.colors.link};
  }
  ${(props) => props.theme.cart.itemAction}
`
export const ToggleIcon = styled(FontAwesomeIcon).attrs((props) => ({
  color: props.theme.cart.cartIcon,
  ...props,
}))``
export const OutOfStockLabel = styled(Box)`
  background: ${(props) => props.theme.colors.black};
  bottom: 0;
  color: ${(props) => props.theme.colors.white};
  font-size: 12px;
  font-weight: 700;
  height: 22px;
  line-height: 22px;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 1;
`
