import { faComment } from "@jewlr/pro-regular-svg-icons/faComment"
import { faCommentsAlt } from "@jewlr/pro-regular-svg-icons/faCommentsAlt"
import {
  Box,
  Button,
  Flex,
  FontAwesomeIcon,
  MediaFlex,
  Typography,
} from "@jewlr/storybook/core"
import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { jewlr_t } from "helpers/application"
import { handleLiveChat } from "live-chat"

import { LinkButton } from "./components"
import FooterAccordion from "./footer-accordion"

const Container = styled(Box)`
  background: ${(props) => props.theme.colors.white};
  border: 0.5px solid ${(props) => props.theme.colors.grey[20]};
  padding: 24px 24px 12px;
`

const TitleContainer = styled(Flex)`
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`

const Title = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
`

const HelpText = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 16px;
  a {
    font-weight: 700;
  }
`

const HelpLink = styled(Typography).attrs({
  as: "a",
  underline: true,
})`
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary};
  &:focus,
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`

const ContentText = styled(Typography)`
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  margin-bottom: 16px;
`

const LiveChatButton = styled(Button).attrs({
  mb: 4,
  startIcon: <FontAwesomeIcon fontSize="18px" icon={faComment} />,
  variant: "primary",
})`
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
  height: 41px;
  width: 208px;
`

const HelpFooter = () => {
  const deliveryFaq = [
    {
      content: (
        <ContentText>
          Once an order is confirmed, we handcraft your{" "}
          {jewlr_t("terms.jewelry")} right away. After passing quality control,
          we ship your order to your chosen destination. Contact us as soon as
          possible if you need to change your order.
        </ContentText>
      ),
      title: "What happens when I place my order?",
    },
    {
      content: (
        <ContentText>
          {jewlr_t("terms.Personalized")} items are made-to-order, and based on
          the delivery method, the date displayed includes the time for crafting
          and {jewlr_t("terms.shipping")} your item.
        </ContentText>
      ),
      title: "How long will it take?",
    },
    {
      content: (
        <ContentText>
          All orders proudly {jewlr_t("terms.ship")} from the USA and Canada,
          where the {jewlr_t("terms.jewelry")} is made.
        </ContentText>
      ),
      title: `Where does my order ${jewlr_t("terms.ship")} from?`,
    },
  ]

  return (
    <Container>
      <TitleContainer>
        <FontAwesomeIcon
          fontSize={{ _: "20px", tablet: "16px" }}
          icon={faCommentsAlt}
        />
        <Title>Need Help?</Title>
      </TitleContainer>
      <MediaFlex justifyContent="center" lessThan="tablet">
        <LiveChatButton onClick={() => handleLiveChat()}>
          LIVE CHAT NOW!
        </LiveChatButton>
      </MediaFlex>
      <HelpText>
        Call Toll Free{" "}
        <HelpLink
          href={`tel:${sl.config.customer_service_toll_free}`}
          rel="nofollow"
        >
          {sl.config.customer_service_toll_free}
        </HelpLink>{" "}
        or Email{" "}
        <HelpLink
          href={`mailto:${sl.config.customer_service_email}`}
          rel="nofollow"
        >
          {sl.config.customer_service_email}
        </HelpLink>
      </HelpText>
      <FooterAccordion
        id="99-day-returns-warranty"
        title="99-Day Returns & Included Warranty"
      >
        <ContentText>
          You may return your purchase for a full refund (minus any{" "}
          {jewlr_t("terms.shipping")} charges) within 99 days of the original{" "}
          {jewlr_t("terms.shipping")} date.
        </ContentText>
        <ContentText>
          We offer a 1-year manufacturer&apos;s warranty on all{" "}
          {jewlr_t("terms.jewelry")}.
        </ContentText>
        <LinkButton
          data-lc="footer"
          fontSize={12}
          forwardedAs={Link}
          mb={2}
          to="/helpdesk/5e9f516d2c7d3a7e9aeb5bfe/5ea1f1682c7d3a7e9aeb8748"
        >
          Read More About Warranty
        </LinkButton>
      </FooterAccordion>
      <FooterAccordion id="delivery-order-faq" title="Delivery & Order FAQ">
        <Box pl={1}>
          {deliveryFaq.map(({ content, title }) => (
            <FooterAccordion key={title} subAccordion title={title}>
              {content}
            </FooterAccordion>
          ))}
        </Box>
        <LinkButton
          data-lc="footer"
          fontSize={12}
          forwardedAs={Link}
          ml={1}
          my="12px"
          to="/helpdesk/5e9f516d2c7d3a7e9aeb5bfe/5ea1ec7b04286364bc98e7ba"
        >
          Read More About {jewlr_t("terms.Shipping")}
        </LinkButton>
      </FooterAccordion>
    </Container>
  )
}

export default HelpFooter
