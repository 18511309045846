import {
  Box,
  Divider,
  Flex,
  Image,
  MediaBox,
  Typography,
} from "@jewlr/storybook/core"
import React from "react"
import styled from "styled-components"

import { jewlr_t, IS_JEWLR } from "helpers/application"

import EmptyOutCart from "../empty-out-cart"
import Help from "../help-footer"

import AcceptPayments from "./accept-payments"

const IconBlock = styled(Box)`
  padding-bottom: 10px;
  text-align: center;
`

const IconImage = styled(Box).attrs((props) => ({
  as: "img",
  mb: "10px",
  mx: "auto",
  ...props,
}))``

const IconText = styled(Typography).attrs((props) => ({
  as: "h5",
  fontSize: { _: 10, tablet: 14 },
  fontWeight: 500,
  letterSpacing: 0,
  lineHeight: { _: "11px", tablet: "17px" },
  mx: "auto",
  ...props,
}))``

const Footer = () => {
  return (
    <>
      {IS_JEWLR() && (
        <>
          <Box contain pt={{ _: 2, tablet: 0 }} px={{ _: 0, tablet: 0 }}>
            <Divider
              color="grey.20"
              display={{ _: "none", tablet: "block" }}
              height="0.5px"
              mb={5}
              mt="12px"
            />
            <Flex
              gap={{ _: "30px", desktop: 7, tablet: 4 }}
              justifyContent="center"
              mb={{ _: "30px", tablet: 0 }}
            >
              <IconBlock>
                <IconImage
                  alt={`FREE ${jewlr_t("terms.Shipping")}`}
                  height={{ _: "27px", tablet: "48px" }}
                  src={require("images/layout/footer/perks/shipping.svg")}
                  width={{ _: "32px", tablet: "66px" }}
                />
                <IconText width={{ _: "35px", tablet: "49px" }}>
                  FREE {jewlr_t("terms.Shipping")}
                </IconText>
              </IconBlock>

              <IconBlock>
                <IconImage
                  alt="One-Year Warranty"
                  height={{ _: "26px", tablet: "51px" }}
                  src={require("images/layout/footer/perks/1-year-warranty.svg")}
                  width={{ _: "28px", tablet: "55px" }}
                />
                <IconText
                  px={{ tablet: 1 }}
                  width={{ _: "38px", tablet: "112px" }}
                >
                  One-Year Warranty
                </IconText>
              </IconBlock>

              <IconBlock>
                <IconImage
                  alt="99 Day Returns"
                  height={{ _: "23px", tablet: "49px" }}
                  src={require("images/layout/footer/perks/99-day-returns.svg")}
                  width={{ _: "23px", tablet: "49px" }}
                />
                <IconText
                  px={{ tablet: 2 }}
                  width={{ _: "30px", tablet: "85px" }}
                >
                  99-Day Returns
                </IconText>
              </IconBlock>

              <IconBlock>
                <IconImage
                  alt="Made In North America"
                  height={{ _: "24px", tablet: "44px" }}
                  src={require("images/layout/footer/perks/diamond.svg")}
                  width={{ _: "31px", tablet: "58px" }}
                />
                <IconText
                  px={{ tablet: "5px" }}
                  width={{ _: "54px", tablet: "92px" }}
                >
                  Made in North America
                </IconText>
              </IconBlock>
            </Flex>
            <MediaBox lessThan="tablet" mb={5} px={6} textAlign="center">
              <Typography fontSize="18px" fontWeight="700">
                Customer Reviews
              </Typography>
              <Typography fontSize="12px" fontWeight="500" mb="6px">
                {jewlr_t("terms.Jewelry")} And Customer Experience Is Our
                Passion, And Our Customers Love Us For It.
              </Typography>
              <Typography fontSize="18px" fontWeight="700">
                50K+
              </Typography>
              <Image
                alt="customer ratings"
                height="14px"
                src={require("images/cart/customer-reviews.svg")}
                width="70px"
              />
            </MediaBox>
          </Box>
          <MediaBox contain lessThan="tablet" px={2}>
            <Help />
          </MediaBox>
        </>
      )}
      <EmptyOutCart />
      <MediaBox lessThan="tablet">
        <AcceptPayments />
      </MediaBox>
    </>
  )
}
export default Footer
