import { Box, Divider, Flex, Image, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

const StyledLink = styled(Box).attrs((props) => ({
  as: Link,
  ...props,
}))`
  cursor: pointer;
  display: block;
  flex-basis: 50%;
  text-align: center;
  text-decoration: underline;
  ${(props) => props.theme.mediaQueries.tablet`
    flex: 1;
  `}
`

const Heading = styled(Typography).attrs(() => ({
  as: "h2",
  px: 1,
}))`
  ${({ theme }) => `
    color: ${theme.colors.black};
    font-weight: bold;
    letter-spacing: 0;
    margin: 20px 0;
  `}
`

const StartShopping = () => {
  const startShopping = [
    {
      link: "/rings",
      name: "Shop Rings",
      style_code: "JWL0024",
    },
    {
      link: "/necklaces",
      name: "Shop Pendants",
      style_code: "JWLP0122",
    },
    {
      link: "/name-necklaces",
      name: "Shop Name Necklaces",
      style_code: "JWLN0300",
    },
    {
      link: "/bracelets",
      name: "Shop Bracelets",
      style_code: "JWLB0049",
    },
  ]
  return (
    <Box>
      <Divider color="grey10" />
      <Heading>Start Shopping</Heading>
      <Flex flexWrap={{ _: "wrap", tablet: "no-wrap" }} mb={2}>
        {startShopping.map((item) => (
          <StyledLink
            data-lc="start-shopping"
            key={`item_$${item.style_code}`}
            to={item.link}
          >
            <Image
              alt={item.name}
              sizes="256px"
              src={`${sl.config.full_main_render_url}${item.style_code}`}
              srcSet={`${sl.config.full_main_render_url}${item.style_code}?dim=256 256w,
                        ${sl.config.full_main_render_url}${item.style_code}?dim=512 512w,
                        ${sl.config.full_main_render_url}${item.style_code}?dim=1024 1024w,
                      `}
              width="80%"
            />
            <Flex alignItems="center" justifyContent="center">
              <Typography fontSize={16} lineHeight="12px" mr={1}>
                {item.name}
              </Typography>
            </Flex>
          </StyledLink>
        ))}
      </Flex>
    </Box>
  )
}

StartShopping.propTypes = {
  startShopping: PropTypes.array,
}

export default StartShopping
